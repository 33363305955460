<template>
  <div class="tour-overview" v-if="loaded">
    <div class="tour-overview-header">
      <div class="breadcrumbs">
        <div>
          <div class="home-icon">
            <router-link
              :to="{
                name: 'events',
              }"
            >
              <Home />
            </router-link>
            <p class="divider">/</p>
          </div>
          <p>
            Tour
            <template v-if="$route.name == 'tournamentoverview-standings'">
              standings
            </template>
            <template v-if="$route.name == 'tournamentoverview-events'">
              events
            </template>
            <template v-if="$route.name == 'tournamentoverview-stats'">
              stats
            </template>
          </p>
        </div>
      </div>
      <div class="left">
        <h3>{{ tourData.name }}</h3>
        <div class="tour-status" v-if="tourData.status == 'COMPLETED'">
          <span class="status-label strom">Finished</span>
        </div>
      </div>
      <div class="right">
        <div class="number-of-events">
          <span class="icon-wrapper">
            <EventIcon />
          </span>
          {{ tourData.eventsCount }} events
        </div>
        <div class="next-event" v-if="tourData.nextEvent">
          <span class="icon-wrapper">
            <CalendarIcon />
          </span>
          Next event:
          {{ nextEventStartDate }}
        </div>
        <div class="tags">
          <span class="card-label sunset" v-if="tourData.type">{{
            tourData.type.name
          }}</span>
        </div>
      </div>

      <nav>
        <ul class="tour-nav" :class="navClass()">
          <router-link
            :to="{ name: 'tournamentoverview-standings' }"
            v-if="tourData.scoreBasedOnNrOfEvents"
          >
            <li class="standings">Standings</li>
          </router-link>
          <router-link
            :to="{ name: 'tournamentoverview-complete-standings' }"
            v-else
          >
            <li class="standings">Standings</li>
          </router-link>
          <router-link :to="{ name: 'tournamentoverview-events' }">
            <li class="events">Events</li>
          </router-link>
          <router-link :to="{ name: 'tournamentoverview-stats' }">
            <li class="stats">Stats</li>
          </router-link>
        </ul>
      </nav>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import moment from "moment";
import EventIcon from "@/assets/icons/EventIcon";
import CalendarIcon from "@/assets/icons/CalendarIcon";
import Home from "@/assets/icons/Home";

export default {
  name: "PublicTourOverview",
  components: {
    EventIcon,
    CalendarIcon,
    Home,
  },
  data() {
    return {
      loaded: false,
      tourData: null,
    };
  },
  computed: {
    nextEventStartDate() {
      return moment(this.tourData.nextEvent.startDate).format("ddd D MMM");
    },
  },
  methods: {
    navClass() {
      if (this.$route.name == "tournamentoverview-events") return "events";
      if (
        this.$route.name == "tournamentoverview-standings" ||
        this.$route.name == "tournamentoverview-complete-standings"
      )
        return "standings";
      if (this.$route.name == "tournamentoverview-stats") return "stats";

      return "";
    },
    async loadData() {
      let tourInfo = await this.$axios({
        data: {
          query: `
          {
            tour(tourId: "${this.$router.currentRoute.params.tourId}") {
              id
              name
              eventsCount
              status
              scoreBasedOnNrOfEvents
              nextEvent{
                startDate
              }
              type{
                name
                type
              }
            }
          }
          `,
        },
      });

      this.tourData = tourInfo.data.data.tour;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.fader-enter-active,
.fader-leave-active {
  transition: opacity 0.3s;
}
.fader-enter, .fader-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px);
}
.tour-overview-header {
  display: flex;
  flex-wrap: wrap;
  padding: 0 12px;
  max-width: 100%;

  h3 {
    padding-left: 6px;
    @include Gilroy-Bold;
    font-size: 24px;
    margin: 0;
    width: 100%;
    display: block;
  }

  .breadcrumbs {
    padding-left: 6px;
    margin: 24px 0 36px 0;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    .home-icon {
      display: flex;
      align-items: center;
      font-size: 12px;
      svg {
        margin-bottom: 4px;
      }
    }

    div {
      display: flex;
      align-items: center;
      a {
        color: $ocean;
        &:hover {
          text-decoration: underline;
        }
      }
      p {
        font-size: 14px;
        margin: 0;

        &.divider {
          min-width: 12px;
          text-align: center;
        }
      }
    }
  }
  .right {
    display: none;
  }
  .left {
    width: 100%;

    .tour-status {
      margin-top: 18px;
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
      }
      p {
        margin: 0;
        margin-left: 10px;
        font-size: 18px;
      }
    }
  }

  nav {
    padding: 0 6px;
    display: flex;
    width: 100%;
    margin: 32px 0 24px 0;
    ul {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 0;
      list-style: none;

      li {
        margin-right: 32px;
        font-size: 16px;
        padding: 0 1px 4px 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
          content: "";
          width: 100%;
          height: 2px;
          background: transparent;
          position: absolute;
          bottom: -2px;
          left: 0;
          z-index: 2;
        }
      }

      .router-link-exact-active {
        li {
          position: relative;
          z-index: 1;
          &::after {
            content: "";
            width: 100%;
            height: 2px;
            background: $midnight;
            position: absolute;
            bottom: -2px;
            left: 0;
            z-index: 2;
          }
          //border-bottom: 2px solid $midnight;
        }
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .tour-overview-header {
    padding: 0;

    h3 {
      font-size: 32px;
      padding-left: 0;
    }

    .breadcrumbs {
      padding: 50px 0 40px 0;
      border-right: 1px solid $fog;
      width: 75%;
      margin: 0;
      display: flex;
      align-items: center;

      .home-icon {
        svg {
          width: 18px;
          height: auto;
        }
      }
      div {
        a {
          color: $ocean;
        }
        p {
          font-size: 16px;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding-left: 24px;

      .icon-wrapper {
        display: flex;
        align-items: center;
        width: 30px;
        justify-content: center;
        margin-right: 10px;
      }

      .next-event {
        margin-top: 16px;
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .number-of-events {
        display: flex;
        align-items: center;
        font-size: 18px;
      }

      .tags {
        margin-top: 64px;
        display: block;
      }
    }
    .left {
      width: 75%;
      margin-top: 0;
      border-right: 1px solid $fog;
      padding-right: 24px;
      flex-direction: column;
      justify-content: space-between;
      display: flex;

      .tour-status {
        svg {
          width: 24px;
          height: 24px;
          font-size: 18px;
        }
      }
    }

    nav {
      padding: 0 6px;
      display: flex;
      width: 100%;
      margin: 32px 0 64px 0;
      ul {
        border-top: 1px solid $fog;
        border-bottom: 1px solid $fog;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        padding: 16px 0 0 0;
        list-style: none;
        position: relative;

        &::after {
          content: "";
          height: 3px;
          background: $midnight;
          position: absolute;
          bottom: 0px;
          left: 0;
          z-index: 3;
          transition: all ease 0.3s;
        }

        &.standings {
          &::after {
            width: 90px;
            left: 0px;
          }
        }
        &.events {
          &::after {
            width: 60px;
            left: 122px;
          }
        }
        &.stats {
          &::after {
            width: 46px;
            left: 214px;
          }
        }
        li {
          font-size: 18px;
          padding-bottom: 16px;
          margin-right: 32px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          &.events {
            width: 60px;
          }
          &.standings {
            width: 90px;
          }
          &.stats {
            width: 46px;
          }
          &:hover {
            &::after {
              content: "";
              height: 3px;
              background: $sleet;
              position: absolute;
              bottom: -1px;
              left: 0;
              z-index: 2;
              transition: all ease 0.3s;
              width: 100%;
            }
          }
        }
        .router-link-exact-active {
          li {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>
